import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import axios from "axios";

const RedeemPointsModal = ({ member, open, onClose, onSuccess }) => {
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [employeePasscode, setEmployeePasscode] = useState("");
  const [error, setError] = useState("");
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  useEffect(() => {
    if (open) {
      axios
        .get("/api/rewards/offers")
        .then((response) => setOffers(response.data))
        .catch((error) => console.error("Error fetching offers:", error));
    }
  }, [open]);

  const validatePasscode = async (passcode) => {
    if (!passcode) {
      throw new Error("Employee passcode is required.");
    }

    try {
      const response = await axios.post("/api/employees/validatePasscode", {
        passcode,
      });

      if (!response.data.valid) {
        throw new Error("Invalid employee passcode.");
      }
    } catch (err) {
      throw new Error(
        err.response?.data?.message || "Error validating passcode."
      );
    }
  };

  const handleRedeem = async () => {
    setError("");
    if (!selectedOffer) {
      setError("Please select an offer to redeem.");
      return;
    }

    try {
      await validatePasscode(employeePasscode);

      await axios.post(`/api/digitalLoyalty/${member._id}/redeem`, {
        offerId: selectedOffer,
        employeeId: employeePasscode,
      });

      setSuccessSnackbar(true);
      clearFields();
      onSuccess();
      onClose();
    } catch (err) {
      console.error(err);
      setError(err.message || "Error redeeming points.");
    }
  };

  const clearFields = () => {
    setSelectedOffer(null);
    setEmployeePasscode("");
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            sx={{ mb: 3, fontWeight: "bold" }}>
            Redeem Points
          </Typography>
          {error && <Typography color="error">{error}</Typography>}

          {/* Offer Selection */}
          <RadioGroup
            sx={{ mb: 3 }}
            value={selectedOffer}
            onChange={(e) => setSelectedOffer(e.target.value)}>
            {offers.map((offer) => (
              <FormControlLabel
                key={offer._id}
                value={offer._id}
                control={<Radio />}
                label={`${offer.name} - ${offer.pointsRequired} points`}
                disabled={member.points < offer.pointsRequired}
              />
            ))}
          </RadioGroup>

          {/* Employee Passcode Field */}
          <TextField
            label="Employee Passcode"
            type="password"
            fullWidth
            margin="normal"
            value={employeePasscode}
            onChange={(e) => setEmployeePasscode(e.target.value)}
          />

          {/* Redeem Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleRedeem}
            sx={{ mt: 2 }}>
            Redeem
          </Button>

          {/* Cancel Button */}
          <Button
            variant="text"
            color="secondary"
            fullWidth
            onClick={onClose}
            sx={{ mt: 1 }}>
            Cancel
          </Button>
        </Box>
      </Modal>

      {/* Success Snackbar */}
      <Snackbar
        open={successSnackbar}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => setSuccessSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}>
          Points redeemed successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default RedeemPointsModal;
