// import React, { useState } from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Tabs,
//   Tab,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Button,
// } from "@mui/material";
// import axios from "axios"; // For making API requests

// const LoyaltyCard = () => {
//   const [searchInput, setSearchInput] = useState("");
//   const [customerData, setCustomerData] = useState(null);
//   const [error, setError] = useState("");
//   const [tabIndex, setTabIndex] = useState(0);

//   const handleTabChange = (event, newIndex) => {
//     setTabIndex(newIndex);
//   };

//   const handleSearch = async () => {
//     setError("");
//     try {
//       const response = await axios.get(
//         `/api/digitalLoyalty/searchByEmailOrPhone?search=${encodeURIComponent(
//           searchInput
//         )}`
//       );
//       setCustomerData(response.data);
//     } catch (err) {
//       setError("Customer not found. Please check the input and try again.");
//     }
//   };

//   return (
//     <Card sx={{ maxWidth: 600, margin: "auto", mt: 4, boxShadow: 3 }}>
//       <CardContent>
//         <Typography variant="h5" align="center" sx={{ mb: 2 }}>
//           Search Loyalty Member
//         </Typography>

//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             gap: 1,
//             mb: 3,
//           }}>
//           <TextField
//             label="Email or Phone Number"
//             variant="outlined"
//             value={searchInput}
//             onChange={(e) => setSearchInput(e.target.value)}
//             fullWidth
//           />
//           <Button variant="contained" color="primary" onClick={handleSearch}>
//             Search
//           </Button>
//         </Box>

//         {error && (
//           <Typography color="error" align="center" sx={{ mb: 2 }}>
//             {error}
//           </Typography>
//         )}

//         {customerData ? (
//           <>
//             <Typography variant="h4" align="center" sx={{ mb: 2 }}>
//               Points: {customerData.points}
//             </Typography>

//             <Tabs
//               value={tabIndex}
//               onChange={handleTabChange}
//               centered
//               sx={{ borderBottom: 1, borderColor: "divider" }}>
//               <Tab label="History" />
//               <Tab label="Rewards" />
//             </Tabs>

//             <Box sx={{ p: 2 }}>
//               {tabIndex === 0 && (
//                 <TableContainer>
//                   <Table>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>Type</TableCell>
//                         <TableCell>Points</TableCell>
//                         <TableCell>Reason</TableCell>
//                         <TableCell>Date</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {customerData.pointsActivity.map((activity, index) => (
//                         <TableRow key={index}>
//                           <TableCell>{activity.type}</TableCell>
//                           <TableCell>{activity.points}</TableCell>
//                           <TableCell>{activity.reason}</TableCell>
//                           <TableCell>
//                             {new Date(activity.timestamp).toLocaleDateString()}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               )}

//               {tabIndex === 1 && (
//                 <TableContainer>
//                   <Table>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>Reward</TableCell>
//                         <TableCell>Points Required</TableCell>
//                         <TableCell>Status</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {customerData.rewardOffers.map((offer, index) => (
//                         <TableRow key={index}>
//                           <TableCell>{offer.name}</TableCell>
//                           <TableCell>{offer.pointsRequired}</TableCell>
//                           <TableCell>
//                             {customerData.points >= offer.pointsRequired
//                               ? "Target Reached"
//                               : "Not Enough Points"}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               )}
//             </Box>
//           </>
//         ) : (
//           <Typography align="center">
//             Enter a search term to view details
//           </Typography>
//         )}
//       </CardContent>
//     </Card>
//   );
// };

// export default LoyaltyCard;

import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";

const LoyaltyCard = () => {
  const [searchInput, setSearchInput] = useState("");
  const [customerData, setCustomerData] = useState(null);
  const [rewardOffers, setRewardOffers] = useState([]);
  const [error, setError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);

    if (newIndex === 1 && rewardOffers.length === 0) {
      fetchRewardOffers();
    }
  };

  const handleSearch = async () => {
    setError("");
    setCustomerData(null);
    try {
      const response = await axios.get(
        `/api/digitalLoyalty/searchByEmailOrPhone?search=${encodeURIComponent(
          searchInput
        )}`
      );
      setCustomerData(response.data);
    } catch (err) {
      setError("Customer not found. Please check the input and try again.");
    }
  };

  const fetchRewardOffers = async () => {
    try {
      const response = await axios.get("/api/rewards/offers");
      setRewardOffers(response.data);
    } catch (err) {
      console.error("Error fetching reward offers:", err);
    }
  };

  return (
    <Card sx={{ maxWidth: 600, margin: "auto", mt: 4, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" align="center" sx={{ mb: 2 }}>
          Search Loyalty Member
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            mb: 3,
          }}>
          <TextField
            label="Email or Phone Number"
            variant="outlined"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Box>

        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {customerData ? (
          <>
            <Typography variant="h4" align="center" sx={{ mb: 2 }}>
              Points: {customerData.points}
            </Typography>

            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              centered
              sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tab label="History" />
              <Tab label="Rewards" />
            </Tabs>

            <Box sx={{ p: 2 }}>
              {tabIndex === 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Points</TableCell>
                        <TableCell>Reason</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerData.pointsActivity.map((activity, index) => (
                        <TableRow key={index}>
                          <TableCell>{activity.type}</TableCell>
                          <TableCell>{activity.points}</TableCell>
                          <TableCell>{activity.reason}</TableCell>
                          <TableCell>
                            {new Date(activity.timestamp).toLocaleDateString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {tabIndex === 1 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Reward</TableCell>
                        <TableCell>Points Required</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rewardOffers.map((offer, index) => (
                        <TableRow key={index}>
                          <TableCell>{offer.name}</TableCell>
                          <TableCell>{offer.pointsRequired}</TableCell>
                          <TableCell>
                            {customerData.points >= offer.pointsRequired
                              ? "Target Reached"
                              : "Not Enough Points"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </>
        ) : (
          <Typography align="center">
            Enter a search term to view details
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default LoyaltyCard;
