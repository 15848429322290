/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrders,
  selectPastOrders,
  selectQuery,
  orderInfoSet,
} from "../../../../redux/reducers/admin";
import Status from "../Status";
import OrderMenu from "../OrderMenu";
import CakeDetail from "./cakeDetail";
import SortProperty from "../sortProperty";
import dayjs from "dayjs";
import OrderPartialDetail from "./orderPartialDetails";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90% !important",
    margin: "20px 0 20px 0 !important",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "23px 25px 23px 25px",
    backgroundColor: "#F1F1F1 !important",
    borderColor: "#8B8A98",
    borderStyle: "none none solid none",
    borderRadius: "20px 20px 0 0",
  },
  orderIdLink: {
    fontSize: "14px !important !important",
    color: "blue !important",
    cursor: "pointer !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  orderCollapsedSummary: {
    display: "flex !important",
    flexDirection: "row !important",
    borderStyle: "none none solid none !important",
    borderWidth: "1px !important",
    borderColor: "#CECCDD !important",
  },
  orderExpandedSummary: {
    display: "flex !important",
    flexDirection: "row !important",
    borderStyle: "none none solid none !important",
    borderWidth: "1px !important",
    borderColor: "#CECCDD !important",
    padding: "0 25px 0 25px !important",
    backgroundColor: "#FFDDD8 !important",
  },
  gridItemBreakpoint: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "flex-start !important",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  gridItem: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "flex-start !important",
  },
  statusContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "flex-end ",
  },
  detailItem: {
    display: "flex !important",
  },
  orderDetailText: {
    display: "flex",
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  orderDetailSubText: {
    fontSize: "12px !important",
    color: "#676767 !important",
  },
}));

function AccordionOrderList({
  setOrderOpen,
  setDeleteDialogOpen,
  setFilteredCost,
}) {
  const [expanded, setExpanded] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const handleOrderExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sortedOrders = useSelector(selectOrders);
  const pastOrders = useSelector(selectPastOrders);
  let orderListToBeRendered = [];
  {
    /*  if the list of pastOrders is empty it means the admin has not requested to see past orders */
  }
  if (pastOrders.length === 0) {
    orderListToBeRendered = sortedOrders;
  } else {
    orderListToBeRendered = pastOrders;
  }
  const query = useSelector(selectQuery);

  let totalFilteredCost = 0;

  return (
    <Box className={classes.tableContainer} data-testid="accordion-order-list">
      <Grid container spacing={0} className={classes.header}>
        <Grid item xs={7} sm={4} className={classes.gridItem}>
          <SortProperty up="nameAsc" down="nameDec">
            Customer
          </SortProperty>
        </Grid>
        <Grid item xs={5} sm={4} className={classes.gridItem}>
          <SortProperty up="pickupDateAsc" down="pickupDateDec">
            Pick Up Date
          </SortProperty>
        </Grid>
        {isLandscape && (
          <Grid item xs={5} sm={4} className={classes.gridItem}>
            <SortProperty up="orderDateAsc" down="orderDateDec">
              Order Date
            </SortProperty>
          </Grid>
        )}
      </Grid>

      {orderListToBeRendered &&
        orderListToBeRendered.length > 0 &&
        orderListToBeRendered.map((order, i) => {
          let singleOrder = null;
          if (order.edits && order.edits.length > 0) {
            const lastElement = order.edits.slice(-1).pop();
            singleOrder = lastElement.order;
          } else {
            singleOrder = order;
          }
          const orderDate = dayjs(singleOrder.orderDate).format("YYYY-MM-DD");
          const pickupDate = dayjs(singleOrder.collectionDate).format(
            "YYYY-MM-DD"
          );

          if (
            singleOrder.orderId.search(query.orderId) !== -1 &&
            singleOrder.customerInfo.name.search(query.customerName) !== -1 &&
            singleOrder.email.search(query.customerEmail) !== -1 &&
            pickupDate.search(query.pickupDate) !== -1
          ) {
            totalFilteredCost = totalFilteredCost + singleOrder.total;

            return (
              <Accordion
                elevation={0}
                style={{
                  width: "100%",
                  margin: expanded === `panel${i}` ? "0" : "",
                }}
                expanded={expanded === `panel${i}`}
                onChange={handleOrderExpand(`panel${i}`)}>
                <AccordionSummary
                  className={
                    expanded === `panel${i}`
                      ? classes.orderExpandedSummary
                      : classes.orderCollapsedSummary
                  }>
                  <Grid container spacing={0}>
                    <Grid item xs={7} sm={4} className={classes.gridItem}>
                      <Box className={classes.item}>
                        <Typography className={classes.orderDetailText}>
                          {singleOrder.customerInfo.name}
                        </Typography>
                        <Typography className={classes.orderDetailSubText}>
                          {singleOrder.email.length < 30
                            ? singleOrder.email
                            : singleOrder.email.substring(0, 30) + "..."}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5} sm={4} className={classes.gridItem}>
                      <Box className={classes.item}>
                        <Typography className={classes.orderDetailText}>
                          {pickupDate}
                        </Typography>
                        <Typography className={classes.orderDetailSubText}>
                          {singleOrder.collectionTime}
                        </Typography>
                      </Box>
                    </Grid>
                    {isLandscape && (
                      <Grid item xs={5} sm={4} className={classes.gridItem}>
                        <Box className={classes.item}>
                          <Typography className={classes.orderDetailText}>
                            {orderDate}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <Box className={classes.detailItem}>
                    <Grid container spacing={0}>
                      {singleOrder.products.map((product, i) => {
                        return (
                          <>
                            <CakeDetail product={product} />
                            <Divider
                              style={{ width: "100%", marginBottom: "15px" }}
                            />
                          </>
                        );
                      })}

                      <OrderPartialDetail
                        label={"Order Date"}
                        val={orderDate}
                      />
                      <OrderPartialDetail
                        label={"Pickup Location"}
                        val={
                          singleOrder.collectionLocation
                            ? singleOrder.collectionLocation
                            : ""
                        }
                      />
                      {singleOrder.additionalNotes !== "n/a" && (
                        <OrderPartialDetail
                          label={"Additional Notes"}
                          val={singleOrder.additionalNotes}
                        />
                      )}
                      <OrderPartialDetail
                        label={"Total"}
                        val={`$${singleOrder.total.toFixed(2)} CAD`}
                      />

                      <Grid item xs={12}>
                        <Button
                          sx={{
                            width: "100%",
                            backgroundColor: "#fd7762",
                            color: "#ffffff",
                          }}
                          onClick={() => {
                            dispatch(orderInfoSet(singleOrder));
                            setOrderOpen(true);
                          }}>
                          DETAILS
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
    </Box>
  );
}

export default AccordionOrderList;
