import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";
import axios from "axios";

const UpdatePointsModal = ({ member, open, onClose, onSuccess }) => {
  const [transactionSubtotal, setTransactionSubtotal] = useState("");
  const [addPointsReason, setAddPointsReason] = useState("in-store purchase");
  const [pointsToDeduct, setPointsToDeduct] = useState("");
  const [deductionReason, setDeductionReason] = useState("employee error");
  const [addPointsPasscode, setAddPointsPasscode] = useState("");
  const [deductPointsPasscode, setDeductPointsPasscode] = useState("");
  const [addPointsError, setAddPointsError] = useState("");
  const [deductPointsError, setDeductPointsError] = useState("");
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const addPointsReasons = [
    "in-store purchase",
    "promotional",
    "customer appreciation",
    "event participation",
  ];

  const deductionReasons = [
    "employee error",
    "customer return",
    "fraudulent activity",
    "account adjustment",
    "program violation",
    "system_error",
  ];

  const validatePasscode = async (passcode) => {
    if (!passcode) {
      throw new Error("Employee passcode is required.");
    }

    try {
      const response = await axios.post("/api/employees/validatePasscode", {
        passcode,
      });

      if (!response.data.valid) {
        throw new Error("Invalid employee passcode.");
      }
    } catch (err) {
      throw new Error(
        err.response?.data?.message || "Error validating passcode."
      );
    }
  };

  const handleAddPoints = async () => {
    setAddPointsError("");
    try {
      await validatePasscode(addPointsPasscode);

      if (
        !transactionSubtotal ||
        isNaN(transactionSubtotal) ||
        transactionSubtotal <= 0
      ) {
        setAddPointsError("Please enter a valid transaction subtotal.");
        return;
      }

      await axios.post(`/api/digitalLoyalty/${member._id}/addPoints`, {
        subtotal: parseFloat(transactionSubtotal),
        reason: addPointsReason,
        employee: addPointsPasscode,
      });

      setSuccessSnackbar(true);
      clearFields();
      onSuccess();
      onClose();
    } catch (err) {
      setAddPointsError(err.message);
    }
  };

  const handleDeductPoints = async () => {
    setDeductPointsError("");
    try {
      await validatePasscode(deductPointsPasscode);

      if (!pointsToDeduct || isNaN(pointsToDeduct) || pointsToDeduct <= 0) {
        setDeductPointsError(
          "Please enter a valid number of points to deduct."
        );
        return;
      }

      if (!deductionReason) {
        setDeductPointsError("Please select a reason for the deduction.");
        return;
      }

      await axios.post(`/api/digitalLoyalty/${member._id}/deductPoints`, {
        points: parseInt(pointsToDeduct, 10),
        reason: deductionReason,
        employee: deductPointsPasscode,
      });

      setSuccessSnackbar(true);
      clearFields();
      onSuccess();
      onClose();
    } catch (err) {
      setDeductPointsError(err.message);
    }
  };

  const clearFields = () => {
    setTransactionSubtotal("");
    setAddPointsReason("in-store purchase");
    setPointsToDeduct("");
    setDeductionReason("employee error");
    setAddPointsPasscode("");
    setDeductPointsPasscode("");
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
          <Box sx={{ textAlign: "center", mb: 5, fontWeight: "bold" }}>
            <Typography variant="h4" gutterBottom>
              Update Points
            </Typography>
          </Box>

          {/* Add Points Section */}
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: "bold", fontSize: "18px" }}>
            Add Points
          </Typography>
          {addPointsError && (
            <Typography color="error">{addPointsError}</Typography>
          )}
          <TextField
            label="Transaction Subtotal"
            type="number"
            fullWidth
            margin="normal"
            value={transactionSubtotal}
            onChange={(e) => setTransactionSubtotal(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="add-points-reason-label">Reason</InputLabel>
            <Select
              labelId="add-points-reason-label"
              value={addPointsReason}
              onChange={(e) => setAddPointsReason(e.target.value)}>
              {addPointsReasons.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Employee Passcode (Add Points)"
            type="password"
            fullWidth
            margin="normal"
            value={addPointsPasscode}
            onChange={(e) => setAddPointsPasscode(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddPoints}
            sx={{ mt: 2 }}>
            Add Points
          </Button>

          <Divider sx={{ mt: 5 }} />

          {/* Deduct Points Section */}
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ mt: 2, fontWeight: "bold", fontSize: "18px" }}>
            Deduct Points
          </Typography>
          {deductPointsError && (
            <Typography color="error">{deductPointsError}</Typography>
          )}
          <TextField
            label="Points to Deduct"
            type="number"
            fullWidth
            margin="normal"
            value={pointsToDeduct}
            onChange={(e) => setPointsToDeduct(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="deduction-reason-label">Reason</InputLabel>
            <Select
              labelId="deduction-reason-label"
              value={deductionReason}
              onChange={(e) => setDeductionReason(e.target.value)}>
              {deductionReasons.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Employee Passcode (Deduct Points)"
            type="password"
            fullWidth
            margin="normal"
            value={deductPointsPasscode}
            onChange={(e) => setDeductPointsPasscode(e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleDeductPoints}
            sx={{ mt: 2 }}>
            Deduct Points
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => setSuccessSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}>
          Points updated successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdatePointsModal;
