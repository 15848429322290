import React, { useState, useEffect } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import axios from "axios";
import LoyaltyMemberList from "./loyaltyMemberList";
import AddMemberModal from "./addMemberModal";

const LoyaltyMembersPage = () => {
  const [members, setMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Fetch initial 100 members
  const fetchMembers = async (search = "", limit = 100) => {
    try {
      const { data } = await axios.get("/api/digitalLoyalty", {
        params: { search, limit },
      });
      setMembers(data);
    } catch (error) {
      console.error("Error fetching loyalty members", error);
    }
  };

  useEffect(() => {
    fetchMembers(); // Fetch initial 100 members on page load
  }, []);

  const handleSearch = () => {
    fetchMembers(searchQuery); // Trigger search when button is clicked
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Loyalty Members Management
      </Typography>
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <TextField
          label="Search by name, email or phone number"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          sx={{ padding: "15px 30px" }}>
          Search
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddModalOpen(true)}>
          Add Member
        </Button>
      </Box>
      {/* Add Member Modal */}
      <AddMemberModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        refreshMembers={() => fetchMembers(searchQuery)}
      />
      <LoyaltyMemberList
        members={members}
        refreshMembers={() => fetchMembers(searchQuery)}
      />
    </Container>
  );
};

export default LoyaltyMembersPage;
