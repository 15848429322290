import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const AddMemberModal = ({ open, onClose, onAddMember }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    channel: "in-store",
    employeePasscode: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [showSnackbar, setShowSnackbar] = useState(false);

  const channelOptions = ["in-store", "online"];

  // Validate form fields
  const validateForm = async () => {
    const errors = {};

    if (!formData.firstName.trim()) {
      errors.firstName = "First name cannot be blank.";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last name cannot be blank.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email address.";
    }
    if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number must be exactly 10 digits.";
    }

    // Validate employee passcode against the employee collection
    try {
      const response = await axios.post("/api/employees/validatePasscode", {
        passcode: formData.employeePasscode,
      });
      if (!response.data.valid) {
        errors.employeePasscode = "Invalid employee passcode.";
      }
    } catch (error) {
      console.error("Error validating passcode:", error);
      errors.employeePasscode = "Error validating passcode.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (await validateForm()) {
      try {
        await axios.post("/api/digitalLoyalty", {
          ...formData,
        });
        setShowSnackbar(true);
        onAddMember(); // Refresh member list
        onClose(); // Close modal
      } catch (error) {
        console.error("Error adding member:", error);
      }
    }
  };

  // Handle form field changes
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setFormErrors((prev) => ({ ...prev, [field]: undefined })); // Clear error for the field
  };

  // Handle phone number input with validation
  const handlePhoneChange = (value) => {
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericValue.length <= 10) {
      handleChange("phone", numericValue);
    }
    if (!/^\d*$/.test(value)) {
      setFormErrors((prev) => ({
        ...prev,
        phone: "Only numbers are allowed.",
      }));
    } else {
      setFormErrors((prev) => ({ ...prev, phone: undefined })); // Clear phone errors
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ mb: 3, fontWeight: "bold" }}>
            Add New Member
          </Typography>
          <TextField
            fullWidth
            label="First Name"
            value={formData.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Last Name"
            value={formData.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            error={!!formErrors.email}
            helperText={formErrors.email}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Phone Number"
            value={formData.phone}
            onChange={(e) => handlePhoneChange(e.target.value)}
            error={!!formErrors.phone}
            helperText={formErrors.phone}
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 10 }} // Prevents further typing beyond 10 digits
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Channel</InputLabel>
            <Select
              value={formData.channel}
              onChange={(e) => handleChange("channel", e.target.value)}>
              {channelOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Employee Passcode"
            type="password"
            value={formData.employeePasscode}
            onChange={(e) => handleChange("employeePasscode", e.target.value)}
            error={!!formErrors.employeePasscode}
            helperText={formErrors.employeePasscode}
            sx={{ mb: 2 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}>
          Member added successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddMemberModal;
