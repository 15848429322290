/** @format */
import { Divider } from "@mui/material";
import OrderPartialDetail from "./orderPartialDetails";

function CakeDetail({ product }) {
  return (
    <>
      <OrderPartialDetail label={"Image"} val={product.imgPath} />
      {product.size && <OrderPartialDetail label={"Size"} val={product.size} />}
      {product.flavor && (
        <OrderPartialDetail label={"Flavor"} val={product.flavor} />
      )}
      {(product.type === "regular-order-dessert" ||
        product.type === "christmas") && (
        <OrderPartialDetail label={"Name"} val={product.name} />
      )}
      {product.color && product.color !== "n/a" && (
        <OrderPartialDetail label={"Color"} val={product.color} />
      )}
      {product.type === "grazeBox" && (
        <>
          <OrderPartialDetail
            label={"Dietary Restriction"}
            val={product.diet}
          />
          <OrderPartialDetail label={"Packaging"} val={product.packaging} />
          <OrderPartialDetail label={"Style"} val={product.style} />
        </>
      )}
      {/* the below implementation for showing the number of a number cake relies on the fact
          that only number cakes are customizable (and maybe the raspberry tower cake) and hence have the length greater than 0.
          This is temp solution until a permanent one is in place      
      */}
      {(product.type === "specialtyCake" || product.type === "custom-cake") &&
        product.customizations &&
        product.customizations.length > 0 && (
          <OrderPartialDetail
            label={"Numbers"}
            val={product.customizations[0].number}
          />
        )}
      <OrderPartialDetail label={"Quantity"} val={product.quantity} />
    </>
  );
}

export default CakeDetail;
