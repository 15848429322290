import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";

const ViewPointsActivityModal = ({ member, open, onClose }) => {
  const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (open && member) {
      axios
        .get(`/api/digitalLoyalty/${member._id}/pointsActivity`)
        .then((response) => setActivity(response.data.activity))
        .catch((err) => console.error("Error fetching points activity:", err));
    }
  }, [member, open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          sx={{ mb: 3, fontWeight: "bold" }}>
          Points Activity
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : activity.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Points</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Subtotal</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activity.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(entry.timestamp).toLocaleString()}
                  </TableCell>
                  <TableCell>{entry.type}</TableCell>
                  <TableCell>{entry.points}</TableCell>
                  <TableCell>${entry.subtotal.toFixed(2)}</TableCell>
                  <TableCell>{entry.reason}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No activity available.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ViewPointsActivityModal;
