import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";

const UpdateCreditsModal = ({ member, open, onClose, onSuccess }) => {
  const [creditAdjustment, setCreditAdjustment] = useState("");
  const [reason, setReason] = useState("customer complaint"); // Default reason
  const [employeePasscode, setEmployeePasscode] = useState("");
  const [error, setError] = useState("");
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const reasons = [
    "customer complaint",
    "redemption",
    "promotional",
    "customer appreciation",
    "employee error",
    "fraudulent activity",
    "account adjustment",
    "program violation",
    "system error",
  ];

  const validatePasscode = async (passcode) => {
    if (!passcode) {
      throw new Error("Employee passcode is required.");
    }

    try {
      const response = await axios.post("/api/employees/validatePasscode", {
        passcode,
      });

      if (!response.data.valid) {
        throw new Error("Invalid employee passcode.");
      }
    } catch (err) {
      throw new Error(
        err.response?.data?.message || "Error validating passcode."
      );
    }
  };

  const handleUpdateCredits = async () => {
    setError("");

    if (!creditAdjustment || isNaN(creditAdjustment)) {
      setError("Please enter a valid credit adjustment value.");
      return;
    }

    if (!reason) {
      setError("Please select a reason for the credit adjustment.");
      return;
    }

    try {
      await validatePasscode(employeePasscode);

      await axios.post(`/api/digitalLoyalty/${member._id}/updateCredit`, {
        credit: parseFloat(creditAdjustment),
        reason,
        employeeId: employeePasscode,
      });

      setSuccessSnackbar(true);
      clearFields();
      onSuccess();
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const clearFields = () => {
    setCreditAdjustment("");
    setReason("customer complaint");
    setEmployeePasscode("");
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            sx={{ mb: 3, fontWeight: "bold" }}>
            Update Credits
          </Typography>
          {error && <Typography color="error">{error}</Typography>}

          {/* Credit Adjustment Field */}
          <TextField
            label="Credit Adjustment"
            type="number"
            fullWidth
            margin="normal"
            value={creditAdjustment}
            onChange={(e) => setCreditAdjustment(e.target.value)}
          />

          {/* Reason Dropdown */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="reason-label">Reason</InputLabel>
            <Select
              labelId="reason-label"
              value={reason}
              onChange={(e) => setReason(e.target.value)}>
              {reasons.map((reasonOption, index) => (
                <MenuItem key={index} value={reasonOption}>
                  {reasonOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Employee Passcode Field */}
          <TextField
            label="Employee Passcode"
            type="password"
            fullWidth
            margin="normal"
            value={employeePasscode}
            onChange={(e) => setEmployeePasscode(e.target.value)}
          />

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateCredits}
            sx={{ mt: 2 }}>
            Update Credit
          </Button>
        </Box>
      </Modal>

      {/* Success Snackbar */}
      <Snackbar
        open={successSnackbar}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => setSuccessSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}>
          Credits updated successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateCreditsModal;
