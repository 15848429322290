import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UpdatePointsModal from "./updatePointsModal";
import UpdateCreditsModal from "./updateCreditsModal";
import RedeemPointsModal from "./redeemPointsModal";
import ViewPointsActivityModal from "./viewPointsActivityModal";
import ViewCreditsActivityModal from "./viewCreditsActivityModal";

const LoyaltyMemberList = ({ members, refreshMembers }) => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [openModal, setOpenModal] = useState({
    updatePoints: false,
    updateCredits: false,
    redeemPoints: false,
    viewPointsActivity: false,
    viewCreditsActivity: false,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenModal = (modalName, member) => {
    setSelectedMember(member);
    setOpenModal((prev) => ({ ...prev, [modalName]: true }));
  };

  const handleCloseModal = (modalName) => {
    setOpenModal((prev) => ({ ...prev, [modalName]: false }));
    refreshMembers();
  };

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => setSnackbar({ open: false });

  const handleActionClick = (event, member) => {
    setSelectedMember(member);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => setAnchorEl(null);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>First Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Last Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Phone Number</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Points</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Credit Balance</TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <TableRow key={member._id}>
                <TableCell>{member.firstName}</TableCell>
                <TableCell>{member.lastName}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell>
                  {member.phone === "unknown" ? "" : member.phone}
                </TableCell>
                <TableCell>{member.points}</TableCell>{" "}
                {/* Display member points */}
                <TableCell>${member.creditBalance.toFixed(2)}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(e) => handleActionClick(e, member)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={
                      Boolean(anchorEl) && selectedMember?._id === member._id
                    }
                    onClose={handleCloseActionMenu}>
                    <MenuItem
                      onClick={() => handleOpenModal("updatePoints", member)}>
                      Update Points
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleOpenModal("updateCredits", member)}>
                      Update Credits
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleOpenModal("redeemPoints", member)}>
                      Redeem Points
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleOpenModal("viewPointsActivity", member)
                      }>
                      View Points Activity
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleOpenModal("viewCreditsActivity", member)
                      }>
                      View Credits Activity
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modals */}
      {selectedMember && (
        <>
          <UpdatePointsModal
            open={openModal.updatePoints}
            member={selectedMember}
            onClose={() => handleCloseModal("updatePoints")}
            onSuccess={() => handleSnackbar("Points updated successfully!")}
          />
          <UpdateCreditsModal
            open={openModal.updateCredits}
            member={selectedMember}
            onClose={() => handleCloseModal("updateCredits")}
            onSuccess={() => handleSnackbar("Credits updated successfully!")}
          />
          <RedeemPointsModal
            open={openModal.redeemPoints}
            member={selectedMember}
            onClose={() => handleCloseModal("redeemPoints")}
            onSuccess={() => handleSnackbar("Points redeemed successfully!")}
          />
          <ViewPointsActivityModal
            open={openModal.viewPointsActivity}
            member={selectedMember}
            onClose={() => handleCloseModal("viewPointsActivity")}
          />
          <ViewCreditsActivityModal
            open={openModal.viewCreditsActivity}
            member={selectedMember}
            onClose={() => handleCloseModal("viewCreditsActivity")}
          />
        </>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoyaltyMemberList;
