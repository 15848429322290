/** @format */

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import LandingPage from "./views/LandingPage";
import Product from "./views/Product";
import MyOrders from "./views/MyOrders";
import Cart from "./views/ShoppingCart";
import Login from "./views/Login";
import Signup from "./views/Signup";
import SignupConfirmation from "./views/Signup/signUpConfirmation";
import ResetPassword from "./views/Login/resetPassword";
import Success from "./views/Payment/Success";
import Fail from "./views/Payment/Fail";
import Availability from "./views/ShoppingCart/availability";
import PrivateRouter from "./components/privateRoute";
import BookingPage from "./views/Admin/booking/main";
import CakeOrderForm from "./views/CakeOrderForm";
import ApprovalPage from "./views/CakeOrderForm/ApprovalPage";
import WeddingPackage from "./views/WeddingPackage";
import AdminHomePage from "./views/Admin";
import CorporateEvents from "./views/CorporateEvents";
import SpecialtyCakes from "./views/SpecialtyCakes";
import StandardCakes from "./views/StandardCakes";
import GrazeBoxes from "./views/GrazeBoxes/index";
import OrderRoot from "./views/Admin/orders";
import Cheesecake from "./views/Cheesecakes";
import AffectedUser from "./views/AffectedUsers/affectedUser";
import Patisserie from "./views/Patisserie";
import Holiday from "./views/Holiday";
import EditCollectionInfo from "./views/Admin/booking/modalContent/editOrder/EditCollectionInfo";
import AboutUs from "./views/AboutUs";
import ScrollToTop from "./components/scrollToTop";
import PrivacyPolicy from "./views/Policies/privacyPolicy";
import TermsAndConditions from "./views/Policies/termsAndConditions";
import ContactUs from "./views/ContactUs";
import FAQ from "./views/FAQ";
import Blog from "./views/Blog";
import DecorateCakeLikeAPro from "./views/Blog/articles/decorateCakeLikeAPro";
import WeddingCakeTrend from "./views/Blog/articles/weddingCakeTrends";
import BakingToolsEssentials from "./views/Blog/articles/bakingTools";
import StorageAndTransportTips from "./views/Blog/articles/storageAndTransportTips";
import ProductCategory from "./views/ProductCategory";
import NewTopAppBar from "./components/newAppBar";
import Footer from "./components/footer";
import GrazeBoxDetail from "./views/GrazeBoxes/grazeBoxDetail";
import LoyaltyMemberPage from "./views/Admin/loyaltyProgram";
import LoyaltyCard from "./views/Admin/loyaltyProgram/loyaltyCard";

ReactGA.initialize("G-2KYXWZ6B3D");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/update-info" element={<AffectedUser />} />

        {/* PRODUCT CATEGORIES */}
        <Route exact path="/product-categories" element={<ProductCategory />} />

        {/* CAKES - simple change */}
        <Route
          exact
          path="/cakes/specialty-cakes"
          element={<SpecialtyCakes />}
        />
        <Route
          exact
          path="/cakes/specialty-cakes/product"
          element={<Product />}
        />
        <Route exact path="/cakes/standard-cakes" element={<StandardCakes />} />
        <Route exact path="/cakes/cheesecakes" element={<Cheesecake />} />

        {/* PATISSERIE */}
        <Route
          exact
          path="/patisserie/desserts"
          element={<Patisserie category={"dessert"} />}
        />
        <Route
          exact
          path="/patisserie/cupcakes"
          element={<Patisserie category={"cupcake"} />}
        />
        <Route
          exact
          path="/patisserie/macarons"
          element={<Patisserie category={"macaron"} />}
        />
        <Route
          exact
          path="/patisserie/cheesecakes"
          element={<Patisserie category={"cheesecake"} />}
        />
        <Route
          exact
          path="/patisserie/cookies"
          element={<Patisserie category={"cookie"} />}
        />

        {/* GRAZE BOXES */}
        <Route exact path="/graze-boxes" element={<GrazeBoxes />} />
        <Route exact path="/graze-boxes/details" element={<GrazeBoxDetail />} />

        {/* SERVICES */}
        <Route
          exact
          path="/services/wedding-packages"
          element={<WeddingPackage />}
        />
        <Route
          exact
          path="/services/corporate-events"
          element={<CorporateEvents />}
        />

        {/* HOLIDAY */}
        {/* <Route
          exact
          path="/holiday/christmas"
          element={<Holiday type={"christmas"} />}
        /> */}
        {/* <Route
          exact
          path="/holiday/valentine"
          element={<Holiday type={"valentine"} />}
        /> */}

        {/* STORE ORDER FORM */}
        <Route exact path="/cakes/order-form" element={<CakeOrderForm />} />
        <Route
          exact
          path="/cakes/order-form/approval"
          element={<ApprovalPage />}
        />

        {/* ACCOUNT */}
        <Route
          exact
          path="/my-orders"
          element={
            <PrivateRouter>
              <MyOrders />
            </PrivateRouter>
          }
        />

        {/* SHOPPING CART */}
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/schedule/availability" element={<Availability />} />

        {/* AUTHENTICATION */}
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/signup-confirm" element={<SignupConfirmation />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />

        {/* CONTACT US */}
        <Route exact path="/contact-us" element={<ContactUs />} />

        {/* PAYMENT  */}
        <Route exact path="/payment/success" element={<Success />} />
        <Route exact path="/payment/fail" element={<Fail />} />

        {/* BLOG  */}
        <Route exact path="/blog/article" element={<Blog />} />
        <Route
          exact
          path="/blog/article/10-ways-to-decorate-cake-like-a-pro"
          element={<DecorateCakeLikeAPro />}
        />
        <Route
          exact
          path="/blog/article/9-wedding-cake-trends-that-will-wow-your-guests-in-2024"
          element={<WeddingCakeTrend />}
        />
        <Route
          exact
          path="/blog/article/baking-equipment-essentials"
          element={<BakingToolsEssentials />}
        />
        <Route
          exact
          path="/blog/article/storage-and-transport-tips"
          element={<StorageAndTransportTips />}
        />

        {/* POLICY  */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        />

        {/* ADMIN */}
        <Route exact path="/admin" element={<AdminHomePage />} />
        <Route exact path="/admin/new-order" element={<OrderRoot />} />
        <Route exact path="/admin/booking" element={<BookingPage />} />
        <Route
          exact
          path="/admin/booking/order/edit/customerInfo"
          element={<EditCollectionInfo />}
        />
        <Route
          exact
          path="/admin/booking/order/edit/orderProductInfo"
          element={<EditCollectionInfo />}
        />
        <Route
          exact
          path="/admin/booking/order/edit/collectionInfo"
          element={<EditCollectionInfo />}
        />

        <Route
          exact
          path="/admin/loyalty-program"
          element={<LoyaltyMemberPage />}
        />
        <Route
          exact
          path="/loyalty-program/member-card"
          element={<LoyaltyCard />}
        />
      </Routes>
    </Router>
  );
}

export default App;
