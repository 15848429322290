import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { collectionTypeChosen } from "../../../redux/reducers/cart";
import SectionHeadings from "../../../components/sectionHeadings";
import PickupLocation from "./pickupLocation";
import Email from "./email";
import FirstName from "./firstName";
import PhoneNumber from "./phoneNumber";
import LastName from "./lastName";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DateSelection from "./dateSelection";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RoomIcon from "@mui/icons-material/Room";
import StoreIcon from "@mui/icons-material/Store";
import Delivery from "./delivery";

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  collectionTypesWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
  },
  selectedCollectionTypeWrapper: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#ffddd8",
    transition: "background-color 0.5s ease",
  },
  unselectedDayCardWrapper: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
  },
  dateCardSelected: {
    textAlign: "center",
    color: "#fd7762",
    outline: "1px solid #fd7762",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dateCardUnselected: {
    textAlign: "center",
    outline: "1px solid grey",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function Collection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const signedIn = localStorage.getItem("user");

  const [collectionType, setCollectionType] = useState("pickup");

  const deliveryFee = 9.95;
  const pickupFee = 0;

  const handleCollectionTypeChange = (type) => {
    let payload = {};

    if (type === "pickup") {
      payload["type"] = "pickup";
      payload["price"] = pickupFee;
    } else {
      payload["type"] = "delivery";
      payload["price"] = deliveryFee;
    }

    dispatch(collectionTypeChosen(payload));
    setCollectionType(type);
  };

  const handleDisabledDelivery = () => {
    alert(
      "Delivery service is unavailable from Jan 20th until Feb 26th. If you want to request a delivery for a date outside of this range please continue placing your order and message us with your delivery request"
    );
  };

  return (
    <Paper elevation={10} className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12}>
          <SectionHeadings heading="Collection & Contact Info"></SectionHeadings>
          <Typography textAlign={"center"} marginBottom={5} color={"red"}>
            Delivery service is unavailable from Jan 20th until Feb 26th. If you
            want to request a delivery for a date outside of this range please
            continue placing your order selecting STORE PICKUP and message us
            with your delivery request
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}>
            <Box
              className={
                collectionType === "pickup"
                  ? classes.selectedCollectionTypeWrapper
                  : classes.unselectedDayCardWrapper
              }
              sx={{ mr: 5 }}>
              <Card
                className={
                  collectionType === "pickup"
                    ? classes.dateCardSelected
                    : classes.dateCardUnselected
                }
                sx={{ minWidth: { xs: "100px", sm: "185px" } }}>
                <CardActionArea
                  sx={{ padding: "30px 20px" }}
                  onClick={() => handleCollectionTypeChange("pickup")}>
                  <CardContent>
                    <StoreIcon fontSize="large" sx={{ mb: 2 }} />
                    <Typography sx={{ fontWeight: "bold" }}>
                      Store Pickup
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
            <Box
              className={
                collectionType === "delivery"
                  ? classes.selectedCollectionTypeWrapper
                  : classes.unselectedDayCardWrapper
              }>
              <Card
                sx={{ minWidth: { xs: "100px", sm: "185px" } }}
                className={
                  collectionType === "delivery"
                    ? classes.dateCardSelected
                    : classes.dateCardUnselected
                }>
                <CardActionArea
                  sx={{ padding: "30px 20px" }}
                  onClick={() => handleDisabledDelivery()}
                  // onClick={() => handleCollectionTypeChange("delivery")}
                >
                  <CardContent>
                    <LocalShippingIcon fontSize="large" sx={{ mb: 2 }} />
                    <Typography sx={{ fontWeight: "bold" }}>
                      Delivery ($9.95)
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 5, mt: 3 }} />

      <Grid container>
        <Grid item xs={12} md={6}>
          {collectionType === "pickup" ? (
            <PickupLocation />
          ) : (
            <Delivery placeholder="Full delivery address" icon={<RoomIcon />} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <DateSelection />
        </Grid>
      </Grid>

      {!signedIn && (
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <FirstName placeholder="First name" icon={<PersonOutlinedIcon />} />
          </Grid>
          <Grid item xs={6}>
            <LastName placeholder="Last name" icon={<PersonOutlinedIcon />} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PhoneNumber
            placeholder="Phone number"
            icon={<LocalPhoneOutlinedIcon />}
          />
        </Grid>
        <Grid item xs={6}>
          {!signedIn && (
            <Email placeholder="Email address" icon={<MailOutlineIcon />} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
